<template>
  <div class="artist-container" v-loading.fullscreen.lock="loading">
    <AnalyzedGenre
      :title="'Genres'"
      :description="'Analyzed Genre'"
      @getArtistsByGenre="getArtistsByGenre"
    />
    <SelectedGenreArtists :genre="genre" />
  </div>
</template>
<script>
import AnalyzedGenre from "../components/AnalyzedGenre";
import SelectedGenreArtists from "../components/SelectedGenreArtists";
export default {
  name: "PreFilledGenres",
  components: {
    AnalyzedGenre,
    SelectedGenreArtists,
  },
  data() {
    return {
      loading: false,
      genre: null,
    };
  },
  methods: {
    getArtistsByGenre(genre) {
      this.genre = genre;
    },
  },
};
</script>

<style lang="scss" scoped></style>
