<template>
  <CardToolbar
    v-loading="loading"
    class="crawled-artists"
    title="Artists"
    :show-toolbar-buttons="true"
  >
    <template v-slot:toolbar>
      <el-checkbox
        style="margin-right: 1rem"
        :disabled="artistsList.length === 0"
        v-model="allSelected"
        @change="selectAllClicked"
        label="Select all on page"
        size="large"
      ></el-checkbox>
      <CardHeaderButtonAI
        :disabled="!selectedArtists.length"
        @buttonClicked="addBatchToCRM"
        >+CRM
      </CardHeaderButtonAI>
      <CardHeaderButtonAI solid :disabled="!selectedArtists.length"
        >Update</CardHeaderButtonAI
      >
      <CardHeaderButtonAI solid @click="routeToSandboxGenrePage">
        Genre Profile
      </CardHeaderButtonAI>
    </template>
    <template v-slot:body>
      <el-table
        @sort-change="sortResults"
        style="width: 100%"
        row-key="id"
        :fit="true"
        table-layout="fixed"
        :data="artistsList"
      >
        <el-table-column
          sortable="custom"
          label="Name"
          prop="name"
          fixed
          width="200"
        >
          <template #default="scope">
            {{ scope.row.name ? scope.row.name : "" }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Monthly Listeners"
          prop="spotifyListenersTotal"
          align="right"
          width="200"
        >
          <template #default="scope">
            {{
              scope.row.spotifyListenersTotal
                ? Number(scope.row.spotifyListenersTotal).toLocaleString()
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Growth 24 months"
          prop="listenersGrowth24months"
          align="right"
          width="200"
        >
          <template #default="scope">
            {{
              scope.row.listenersGrowth24months
                ? Number(scope.row.listenersGrowth24months).toLocaleString() +
                  "%"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Growth 36 months"
          prop="listenersGrowth36months"
          align="right"
          width="200"
        >
          <template #default="scope">
            {{
              scope.row.listenersGrowth36months
                ? Number(scope.row.listenersGrowth36months).toLocaleString() +
                  "%"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="# Playlists"
          prop="playlistTotals"
          align="right"
          width="200"
        >
          <template #default="scope">
            {{
              scope.row.playlistTotals
                ? Number(scope.row.playlistTotals).toLocaleString()
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Playlist Reach"
          prop="playlistReach"
          align="right"
          width="200"
        >
          <template #default="scope">
            {{
              scope.row.playlistReach
                ? Number(scope.row.playlistReach).toLocaleString()
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" width="100" label="Action"
          ><template #default="scope"
            ><ArtistMenuComponent
              v-if="scope.row.name"
              :artist="{
                name: scope.row.name ? scope.row.name : '',
                uuid: scope.row.uuid ? scope.row.uuid : null,
              }"
              :buttons="[
                'analytics',
                'discover',
                'crawl',
                scope.row && 'artistDetails',
                'aiSandbox',
              ]"
              :showFollowButton="false"
            ></ArtistMenuComponent></template
        ></el-table-column>
        <el-table-column
          :key="checkBoxKey"
          fixed="right"
          width="80"
          label="Select"
          align="right"
        >
          <template #default="scope">
            <el-checkbox
              :model-value="selectedArtists.includes(scope.row.uuid)"
              :disabled="
                !selectedArtists.includes(scope.row.uuid) &&
                selectedArtists.length > 49
              "
              @change="checkBoxValueChanged($event, scope.row.uuid)"
              label=""
              size="large"
            ></el-checkbox>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt-5"
        v-model:currentPage="localPagination.offset"
        v-model:page-size="localPagination.limit"
        :page-sizes="[5, 10, 20, 50]"
        :small="true"
        layout="total, sizes, prev, pager, next"
        :total="localPagination.total"
        @size-change="paginationChanged"
        @current-change="paginationChanged"
      >
      </el-pagination>
    </template>
  </CardToolbar>
</template>
<script>
import CardToolbar from "../../common/components/CardToolbar";
import { ref, watch } from "vue";
import CardHeaderButtonAI from "@/aiBetaBench/components/CardHeaderButtonAI";
import { getMongoAggregatedArtistByGenre } from "@/api/artists.api";
import { paginate, sortArray } from "@/aiBetaBench/helpers";
import ArtistMenuComponent from "../../artists/components/ArtistMenuComponent";

import { ElNotification } from "element-plus";
import { addArtistsToCRM } from "../../api/hubspot";
export default {
  name: "SelectedGenreArtists",
  components: { CardToolbar, ArtistMenuComponent, CardHeaderButtonAI },
  props: {
    genre: {
      type: String,
    },
  },
  setup(props) {
    const loading = ref(false);
    const artistsList = ref([]);
    const allSelected = ref(false);
    const artistsListWithoutPagination = ref([]);
    const selectedArtists = ref([]);
    const checkBoxKey = ref(0);
    const localPagination = ref({
      offset: 1,
      limit: 10,
      total: 0,
    });
    const sortBy = ref(null);
    const sortDir = ref(null);

    const getArtists = async (genre) => {
      try {
        loading.value = true;
        const { data } = await getMongoAggregatedArtistByGenre(genre);
        artistsList.value = [];
        artistsListWithoutPagination.value = [];
        const artists = data.artists;
        for (const artist of artists) {
          const payload = {
            name: artist.name ? artist.name : "",
            uuid: artist.soundchartsUUID ? artist.soundchartsUUID : null,
            spotifyListenersTotal: artist.spotifyListenersTotal
              ? artist.spotifyListenersTotal
              : "",
            listenersGrowth24months:
              artist.listenersGrowth && artist.listenersGrowth["24Months"]
                ? artist.listenersGrowth["24Months"]
                : "",
            listenersGrowth36months:
              artist.listenersGrowth && artist.listenersGrowth["36Months"]
                ? artist.listenersGrowth["36Months"]
                : "",
            playlistTotals:
              artist.playlistTotals && artist.playlistTotals.total
                ? artist.playlistTotals.total
                : "",
            revenue: artist.revenue ? artist.revenue : "",
            fanbase: artist.fanbase ? artist.fanbase : "",
          };
          artistsListWithoutPagination.value.push(payload);
        }
        localPagination.value.total = data.totalArtists;
        paginationChanged();
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };
    const sortAllDataIfRequired = (array) => {
      if (sortBy.value && sortDir.value) {
        return sortArray(sortBy.value, sortDir.value, array);
      } else {
        return array;
      }
    };
    const sortResults = ({ prop, order }) => {
      sortBy.value = prop;
      sortDir.value = order;
      artistsListWithoutPagination.value = sortAllDataIfRequired(
        JSON.parse(JSON.stringify(artistsListWithoutPagination.value))
      );
      paginationChanged();
    };
    const paginationChanged = () => {
      allSelected.value = false;
      artistsList.value = artistsListWithoutPagination.value;
      artistsList.value = paginate(
        artistsList.value,
        localPagination.value.limit,
        localPagination.value.offset
      );
    };
    watch(
      () => props.genre,
      async (val) => {
        await getArtists(val);
      }
    );
    const routeToSandboxGenrePage = () => {
      if (props.genre) {
        window.open(
          `${process.env.VUE_APP_SANDBOX_GENRE_URI}/?genre=${props.genre}`,
          "_blank"
        );
      }
    };
    const checkBoxValueChanged = (event, id) => {
      allSelected.value = false;
      if (event && !selectedArtists.value.includes(id)) {
        selectedArtists.value.push(id);
      } else if (!event) {
        const index = selectedArtists.value.indexOf(id);
        if (index > -1) {
          selectedArtists.value.splice(index, 1);
        }
      }
    };

    const selectAllClicked = (value) => {
      if (value) {
        selectedArtists.value = artistsList.value.map((object) => object.uuid);
      } else {
        selectedArtists.value = [];
      }
    };

    const addBatchToCRM = async () => {
      if (selectedArtists.value.length) {
        loading.value = true;
        try {
          const mappedArtists = artistsList.value
            .filter((g) => selectedArtists.value.includes(g.uuid))
            .map((g) => {
              return {
                uuid: g.uuid,
                revenue: g.revenue,
                fanbase: g.fanbase,
              };
            });

          addArtistsToCRM(mappedArtists);
          checkBoxKey.value++;
          selectedArtists.value = [];
          allSelected.value = false;
          ElNotification({
            title: "Success",
            message: "Selected artists added to CRM!",
            type: "success",
          });
        } catch (e) {
          ElNotification({
            title: "Error",
            message: "Error adding to CRM, try again later",
            type: "error",
          });
        } finally {
          loading.value = false;
        }
      }
    };

    return {
      loading,
      artistsList,
      selectedArtists,
      checkBoxKey,
      localPagination,
      allSelected,
      paginationChanged,
      sortAllDataIfRequired,
      sortResults,
      routeToSandboxGenrePage,
      checkBoxValueChanged,
      addBatchToCRM,
      selectAllClicked,
    };
  },
};
</script>
