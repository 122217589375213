<template>
  <CardToolbar
    :title="title"
    :description="description"
    :show-toolbar-buttons="true"
    v-loading="loading"
  >
    <template v-slot:toolbar>
      <input
        class="form-control form-control-solid me-3"
        placeholder="Filter"
        v-model="filterQuery"
        @input="onFilterGenres"
      />
    </template>
    <template v-slot:body>
      <el-skeleton :loading="loading" animated>
        <template #default>
          <div class="genres">
            <el-row>
              <el-col
                :span="8"
                v-for="(genre, index) in filteredGenres"
                :key="index"
              >
                <h6
                  class="cursor-pointer genre-name"
                  @click="getGenre(genre.genre)"
                >
                  {{ genre.genre }}
                </h6>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-skeleton>
    </template>
  </CardToolbar>
</template>
<script>
import CardToolbar from "../../common/components/CardToolbar";
import { ref, onMounted } from "vue";
import { getMongoAggregatedGenre } from "@/api/artists.api";
export default {
  name: "AnalyzedGenre",
  emits: ["getArtistsByGenre"],
  components: { CardToolbar },
  props: {
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
  },
  setup(props, ctx) {
    const genres = ref([]);
    const noOfItemsPerRow = ref(0);
    const filteredGenres = ref([]);
    const noOfRows = ref(3);
    const filterQuery = ref("");
    const loading = ref(false);
    const error = ref(false);

    onMounted(async () => {
      try {
        loading.value = true;
        const { data } = await getMongoAggregatedGenre();
        genres.value = data;
        noOfItemsPerRow.value = genres.value.length / noOfRows.value;
        filteredGenres.value = genres.value;
      } catch (e) {
        error.value = true;
        console.error(e);
      } finally {
        loading.value = false;
      }
    });

    const onFilterGenres = () => {
      filteredGenres.value = genres.value.filter((genre) =>
        genre.genre.includes(filterQuery.value)
      );
    };

    const getGenre = (genre) => {
      ctx.emit("getArtistsByGenre", genre);
    };
    return { filterQuery, filteredGenres, loading, onFilterGenres, getGenre };
  },
};
</script>
<style scoped>
.genres {
  height: 25rem !important;
  overflow-y: scroll;
}
.genre-name:hover {
  text-decoration: underline;
}
</style>
